
  import {createControllersWithDescriptors, initAppForPageWrapper} from 'yoshi-flow-editor-runtime/build/esm/viewerScript.js';
  
  
            import controller0 from '/home/builduser/agent00/work/8ffb4ac4079be0ed/cookie-consent-ccpa-link/src/components/DoNotSellMyData/controller.ts';

  
  import * as viewerApp from '/home/builduser/agent00/work/8ffb4ac4079be0ed/cookie-consent-ccpa-link/src/viewer.app.ts';
    var importedApp = viewerApp;

  import {
    
  } from 'yoshi-flow-editor-runtime/build/esm/controller/httpClientProp';

  var sentryConfig = {
      DSN: 'https://024cf4e66e8e4e94a5405f2c4db72d31@sentry.wixpress.com/388',
      id: '024cf4e66e8e4e94a5405f2c4db72d31',
      projectName: 'cookie-consent-ccpa-link',
      teamName: 'cookie-consent',
    };

  var experimentsConfig = {"scope":"test"};

  var translationsConfig = null;

  var defaultTranslations = null;

  var biLogger = null;

  export const initAppForPage = initAppForPageWrapper({
    initAppForPage: importedApp.initAppForPage,
    sentryConfig: sentryConfig,
    experimentsConfig: experimentsConfig,
    inEditor: false,
    biLogger: biLogger,
    projectName: "cookie-consent-ccpa-link",
    biConfig: null,
    appName: "Do Not Sell Data Link",
    appDefinitionId: "2a617b40-167a-4325-9a9f-58396cf47b93",
    translationsConfig: translationsConfig,
    defaultTranslations: defaultTranslations,
    optionalDeps: {
      
    },
  });

  export const createControllers = createControllersWithDescriptors({
     
  }, [{ method: controller0,
          widgetType: "WIDGET_OUT_OF_IFRAME",
          translationsConfig: translationsConfig,
          experimentsConfig: {"scope":"test"},
          defaultTranslations: defaultTranslations,
          biLogger: biLogger,
          biConfig: null,
          controllerFileName: "/home/builduser/agent00/work/8ffb4ac4079be0ed/cookie-consent-ccpa-link/src/components/DoNotSellMyData/controller.ts",
          appName: "Do Not Sell Data Link",
          appDefinitionId: "2a617b40-167a-4325-9a9f-58396cf47b93",
          projectName: "cookie-consent-ccpa-link",
          componentName: "DoNotSellMyData",
          id: "d925427a-dc06-43f5-ad13-ae1f692f0feb" }], "all");
